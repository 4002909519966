import mixpanel from 'mixpanel-browser'

class MixPanelActions {
    enabled = Boolean(process.env.REACT_APP_MIX_PANEL_ACTIVE)

    public actions = {
        init: () => {
            if (this.enabled)
                mixpanel.init(process.env.REACT_APP_MIX_PANEL_KEY ?? '', {
                    debug: true,
                    track_pageview: true,
                    persistence: 'localStorage',
                    ignore_dnt: true,
                })
        },
        identify: (id: any) => {
            if (this.enabled) mixpanel.identify(id)
        },
        alias: (id: any) => {
            if (this.enabled) mixpanel.alias(id)
        },
        track: (name: string, props?: any) => {
            if (this.enabled) mixpanel.track(name, props)
        },
        trackPageView: (pageName: string) => {
            if (this.enabled) mixpanel.track_pageview({ Page: pageName })
        },
        people: {
            set: (props: any) => {
                if (this.enabled) mixpanel.people.set(props)
            },
        },
        reset: () => {
            if (this.enabled) mixpanel.reset()
        },
    }
}

export const MixPanel = new MixPanelActions()

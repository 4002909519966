import React, { FC } from 'react'
import { CircularProgress } from '@mui/material'
import './loader.scss'

interface loaderProps {
    customClassName?: string
}
const Loader: FC<loaderProps> = ({ customClassName }) => {
    return (
        <div className={`loader ${customClassName}`}>
            <div className="loader-container">
                <CircularProgress size={92} thickness={2} />
            </div>
        </div>
    )
}

export default Loader

import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Loader from '../components/common/loader'

const Layout = React.lazy(() => import('../containers/Layout'))

export const Router = () => {
    return (
        <BrowserRouter>
            <React.Suspense fallback={<Loader />}>
                <Routes>
                    <Route path="*" element={<Layout />} />
                </Routes>
            </React.Suspense>
        </BrowserRouter>
    )
}

/**
 * Type Definitions
 */

import { Reducer } from 'react'

export interface GlobalState {}

/**
 * Initial State
 */

export const initialGlobalState: GlobalState = {}

/**
 * Action Definitions
 */

type GlobalActions = {}

/**
 * Reducer Definition
 */

export const globalReducer: Reducer<GlobalState, GlobalActions> = (
    state,
    action
) => {
    return {}
}

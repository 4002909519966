import { FC } from 'react'
import { ThemeProvider } from '@emotion/react'
import { theme } from './theme'
import { GlobalProvider } from './contexts/Global'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

export const Providers: FC = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    {children}
                </LocalizationProvider>
            </GlobalProvider>
        </ThemeProvider>
    )
}

import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const availableLanguages = [
    'en',
    'fr',
    'es',
    'du',
    'pg',
    'kr',
    'sc',
    'tc',
    'jp',
]
const option = {
    order: ['navigator', 'htmlTag', 'path', 'subdomail'],
    checkWhitelist: true,
}

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        whitelist: availableLanguages,
        detection: option,
        interpolation: { escapeValue: false },
        lng: localStorage.getItem('i18nextLng') || 'en',
    })
    .catch((error) => console.log(error))

export default i18n

import React, { createContext, FC, useReducer } from 'react'
import { GlobalState, initialGlobalState, globalReducer } from '../store/Global'

interface GlobalContextInterface extends GlobalState {}

/**
 * Global Context
 * this holds the values that are needed throughout the project
 * or require some sort of long polling strategies
 * like keeping track of current live and notifications
 */
export const GlobalContext = createContext<GlobalContextInterface>({
    ...initialGlobalState,
})

/**
 * Global Provider
 * the is the provider for the global context
 */
export const GlobalProvider: FC = ({ children }) => {
    const [state] = useReducer(globalReducer, initialGlobalState)

    return (
        <>
            <GlobalContext.Provider value={{ ...state }}>
                {children}
            </GlobalContext.Provider>
        </>
    )
}

import { createTheme, Theme } from '@mui/material'

export const theme: Theme = createTheme({
    typography: {
        fontFamily: '"Bebas Neue Pro Regular", sans-serif',
        fontWeightRegular: '300',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 768,
            lg: 1025,
            xl: 1200,
        },
    },
})
